@import "bootstrap";

$paddingLeft: 2.75em;

.seethruboxOn{
  background-color:rgba(92, 184, 92, 0.7) !important;
  background: linear-gradient(90deg, rgba(35, 173, 35, 0.664) 0%, rgba(35, 173, 35, 0.712) 35%, rgba(35, 173, 35, 0.85) 100%)  !important;
  border: 1px solid rgba(21, 177, 21, 0.65) !important;  
  }
  .seethruboxOff{
  background-color: rgba(217, 83, 79, 0.7);
  background: linear-gradient(90deg, rgba(235, 36, 29, 0.664) 0%, rgba(235, 36, 29, 0.712) 35%, rgba(235, 36, 29, 0.85) 100%)  !important;
  border: 1px solid rgba(235, 36, 29, 0.65);  
  }
.standard {
  .preparation-container {
    @extend .container;
    padding-left: $paddingLeft !important;
    .main-panel {
      @extend .col-8;
      .person-card {
        .member-card-header {
          @extend .col-lg-5, .col-12;
        }
        .member-card-content {
          @extend .col-12, .col-lg-7;
        }
      }
    }
    .tips-panel {
      @extend .col-4, .my-4;
    }
    .add-remove-buttons-container {
      @extend .d-flex;
    }
  }
  .something{
    
  }
  .section-panel {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    @extend .container;
    .tips-panel {
    }
  }

  .theme-container {
    .notes-button {
    }
    .songs-header {
    }
    .songs-list > div {
      @extend .col-12, .my-3, .col-lg-6;
    }
  }

  .review-container {
    padding-left: $paddingLeft !important;
    .left-panel {
      @extend .col-12, .col-lg-5;
    }
    .right-panel {
      @extend .col-12, .col-lg-7;
      .person-card {
        .member-card-header {
          @extend .col-12;
        }
        .member-card-content {
          @extend .col-12, .mb-3;
        }
      }
    }
  }
}

.theme-container {
  .notes-button {
  }
  .songs-header {
  }
  .songs-list > div {
    @extend .col-12, .my-3, .col-lg-6;
  }
}

// Horizontal
.zoom-horizontal {
  .preparation-container {
    @extend .container;
    padding-left: $paddingLeft !important;
    
    .tips-panel {
      @extend .d-none;
    }
    .main-panel {
      @extend .col-12;
      .person-card {
        .member-card-header {
          @extend .col-5;
        }
        .member-card-content {
          @extend .col-7, .mb-0;
        }
      }
    }
  }

  .section-panel {
    @extend .container;
    padding-left: $paddingLeft !important;
    top: 0;
    bottom: 0;
    right: 50vw;
    left: 0;
    height: 100vh;
    width: 50vw;
    position: absolute;
    .music {
      display: block !important;
    }
    .tips-panel {
      @extend .d-none;
    }
  }

  .theme-container {
    padding-left: $paddingLeft !important;
    .notes-button {
      @extend .d-none;
    }
    .cues-container {
      @extend .justify-content-center;
      
    }

    .notes-button {
    }
    .songs-header {
      @extend .my-3;
      > button {
        @extend .d-none;
      }
    }
    .songs-list > div {
     
    }
  }

  .review-container {
    padding-left: $paddingLeft !important;
    .left-panel {
      @extend .col-12;
      padding: 0 !important;
    }
    .right-panel {
      @extend .col-12;
      padding: 0 !important;
      .person-card {
        .member-card-header {
          @extend .col-5;
          
        }
        .member-card-content {
          @extend .col-7, .mb-0;
        }
      }
    }
  }
}
